<script setup lang="ts">
import { ref, computed, onMounted, onUnmounted, watch } from 'vue'
import { useRouter } from 'vue-router'
import { useUserStore } from '@/stores/userStore'
import { baseURL } from '@/services/api.js'
import api from '@/services/api'; 

const scrollText = ref('');
const isMobile = ref(false);
const scrollSpeed = ref(40);  // Default speed for the animation
const marqueeContainer = ref(null); // Ref for the scrolling text container
const animationDuration = ref('40s'); // Default animation duration

const router = useRouter()
const userStore = useUserStore()

// Check if the user is logged in
const isLoggedIn = computed(() => !!userStore.token)

// Computed property to determine if the user is an admin
const isAdminUser = computed(() => {
  const adminTypes = ['webmaster', 'sectoragency'];
  return adminTypes.includes(userStore.user?.user_type || '');
});

// Computed property to determine the dashboard link based on user type
const dashboardLink = computed(() => {
  if (isAdminUser.value) {
    return `${baseURL}admin/`;
  }

  const userType = userStore.user?.user_type;
  switch (userType) {
    case 'localinvestor':
      return '/local-investor/home';
    case 'foreigninvestor':
      return '/foreign-investor/home';
    case 'serviceprovider':
      return '/service-provider/home';
    case 'registeredfdi':
      return '/registered-fdi/home';
    case 'approvedfdi':
      return '/approved-fdi/home';
    case 'rma':
      return '/rma/home';
    case 'directorgeneral':
      return '/director/approved-fdi-visa-application';
    case 'secretary':
      return '/secretariat/approved-fdi-visa-application';
    case 'chief':
      return '/chief/approved-fdi-visa-application';
    case 'investbhutan':
      return '/invest-bhutan/approved-fdi-visa-application';
    default:
      return '/';
  }
});

// Function to handle the dashboard button click
const handleDashboardClick = () => {
  if (isAdminUser.value) {
    window.location.href = dashboardLink.value;
  } else {
    router.push(dashboardLink.value);
  }
};

const fetchMarqueeData = async () => {
  try {
    const response = await api.get('/settings/api/marquee/');
    if (response.status === 200) {
      // Filter active items and concatenate headlines with a spacer between them
      const activeMarquees = response.data
        .filter(item => item.is_active)
        .map(item => item.headline)
        .join(' | ');
      scrollText.value = activeMarquees;
      
      // Recalculate the speed and animation time
      await nextTick();  // Wait for DOM updates to finish
      calculateAnimationDuration();
    } else {
      console.error('Error fetching marquee data');
    }
  } catch (error) {
    console.error('Error fetching marquee data:', error);
  }
};

// Function to calculate the width of the text and adjust the animation duration dynamically

const calculateAnimationDuration = () => {
  const container = marqueeContainer.value;
  if (container) {
    const containerWidth = container.offsetWidth;
    const viewportWidth = window.innerWidth;

    // Set a base speed (pixels per second)
    const baseSpeed = 100;  // Adjust this value as needed (speed in pixels per second)
    
    // Calculate the animation duration based on text width and base speed
    const totalDistance = containerWidth + viewportWidth;
    const durationInSeconds = totalDistance / baseSpeed;
    animationDuration.value = `${durationInSeconds}s`;  // Update the animation duration dynamically
  }
};

// Function to check if the screen size is mobile
const checkMobile = () => {
  isMobile.value = window.innerWidth < 768;
};

// Run when the component is mounted
onMounted(() => {
  checkMobile();
  window.addEventListener('resize', checkMobile);
  fetchMarqueeData();  // Fetch marquee data on mount
  window.addEventListener('resize', calculateAnimationDuration);
});

onUnmounted(() => {
  window.removeEventListener('resize', checkMobile);
  window.removeEventListener('resize', calculateAnimationDuration);
});

// Logout function
const logout = () => {
  userStore.logout();
  router.push('/');
};

// Languages list and selected language state
const languages = ref<{ code: string; name: string }[]>([]);
const selectedLanguage = ref('en');

// Watch for changes in the languages array and set default language
watch(languages, (newLanguages) => {
  if (newLanguages.length > 0 && !selectedLanguage.value) {
    selectedLanguage.value = newLanguages[0].code;
  }
});

defineExpose({});
</script>

<template>
  <header class="bg-blue-90 shadow-md">
    <!-- Scrolling text -->
    <div class="overflow-hidden py-2 w-full bg-gray-100">
      <div class="whitespace-nowrap animate-scrolling-text text-bhutan-green">
        {{ scrollText }} <!-- Use dynamic scrollText here -->
      </div>
    </div>

    <div class="container mx-auto px-4 relative">
      <!-- Main header content -->
      <div class="flex flex-col md:flex-row justify-between items-center py-4">
        <!-- Left: Logo and Title -->
        <div class="flex items-center mb-4 md:mb-0">
          <router-link to="/">
            <img src="@/assets/img/rgob.png" alt="Logo" class="h-34 md:h-34 w-auto mr-4" />
          </router-link>
        </div>

        <!-- Center: Second Logo -->
        <div class="md:absolute md:inset-0 flex justify-center items-center pointer-events-none mb-4 md:mb-0">
          <router-link to="/">
            <img src="@/assets/img/logo.png" alt="Logo2" class="h-20 md:h-28 w-auto" />
          </router-link>
        </div>

        <!-- Right: Login-dependent Buttons -->
        <div class="space-x-4">
          <template v-if="isLoggedIn">
            <button @click="handleDashboardClick"
              class="bg-bhutan-green hover:bg-bhutan-green-light text-white font-bold py-2 px-4 rounded transition duration-300">
              {{ isMobile ? 'Dash' : 'Dashboard' }}
            </button>
            <button @click="logout"
              class="bg-bhutan-green hover:bg-bhutan-green-light text-white font-bold py-2 px-4 rounded transition duration-300">
              {{ isMobile ? 'Out' : 'Logout' }}
            </button>
          </template>
          <template v-else>
            <router-link to="/login">
              <button
                class="bg-bhutan-green hover:bg-bhutan-green-light text-white font-bold py-2 px-4 rounded transition duration-300">
                {{ iMobile ? 'Log' : 'Login' }}
              </button>
            </router-link>
            <router-link to="/register">
              <button
                class="bg-bhutan-green hover:bg-bhutan-green-light text-white font-bold py-2 px-4 rounded transition duration-300">
                {{ isMobile ? 'Reg' : 'Register' }}
              </button>
            </router-link>
          </template>
        </div>
      </div>
    </div>
  </header>
</template>

<style scoped>
.spacer {
  display: inline-block;
  width: 200px; /* This creates a 200px space between headlines */
}

@keyframes scrolling-text {
  0% {
    transform: translateX(200%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.animate-scrolling-text {
  display: inline-block;
  animation: scrolling-text 40s linear infinite;
}
</style>
