<script setup lang="ts">
import { RouterView, useRoute } from 'vue-router';
import { computed, onMounted, onUnmounted, ref } from 'vue';
import HeaderComponent from '@/components/Common/HeaderComponent.vue';
import NavBarComponent from '@/components/Common/NavBarComponent.vue';
import FooterComponent from '@/components/Common/FooterComponent.vue';
import ScrollToTopButton from '@/components/Buttons/ScrollToTopButton.vue';
import ChatComponent from '@/components/ChatComponent.vue';
import { useUserStore } from '@/stores/userStore';

const route = useRoute();
const userStore = useUserStore();
userStore.initializeStore();

const isDashboardRoute = computed(() => {
  const dashboardPrefixes = [
    '/dashboard/',
    '/invest-bhutan/',
    '/chief/',
    '/director/',
    '/secretariat/',
    '/approved-fdi/',
    '/registered-fdi/',
    '/service-provider/',
    '/foreign-investor/',
    '/local-investor/',
    '/rma/',
  ];
  return dashboardPrefixes.some((prefix) => route.path.startsWith(prefix));
});

const showChatIcon = computed(() => {
  return !isDashboardRoute.value && !route.path.includes('/dashboard/');
});

// === Automatic Logout after 10 minutes of inactivity ===
const inactivityTime = 1500; // 10 minutes in milliseconds
let logoutTimeout: number | null = null;
const inactivityMessage = ref<string | null>(null); // For showing logout message

// Function to log out the user and clear entire localStorage
const logoutUser = () => {
  if (localStorage.length > 0) {
    // Clear all items from localStorage
    localStorage.clear();
    
    // Optionally clear any user data in the store if needed
    userStore.clearUser();
    
    // Show the logout message
    inactivityMessage.value = 'You have been logged out due to inactivity for more than 10 minutes.';
    
    // Optionally, redirect to login or homepage
    setTimeout(() => {
      inactivityMessage.value = null;
    }, 5000);
    
    // Add your logic to redirect to login if necessary, e.g., `router.push('/login')`
  }
};

// Reset the logout timeout when the user interacts with the app
const resetLogoutTimeout = () => {
  if (logoutTimeout) {
    clearTimeout(logoutTimeout);
  }
  logoutTimeout = setTimeout(logoutUser, inactivityTime);
};

// Monitor activity and reset the timeout
const addActivityListeners = () => {
  window.addEventListener('mousemove', resetLogoutTimeout);
  window.addEventListener('keydown', resetLogoutTimeout);
  window.addEventListener('click', resetLogoutTimeout);
  resetLogoutTimeout(); // Initialize timeout
};

const removeActivityListeners = () => {
  window.removeEventListener('mousemove', resetLogoutTimeout);
  window.removeEventListener('keydown', resetLogoutTimeout);
  window.removeEventListener('click', resetLogoutTimeout);
  if (logoutTimeout) {
    clearTimeout(logoutTimeout);
  }
};

// Check if the user is logged in and set up the inactivity tracking
onMounted(() => {
  const token = localStorage.getItem('authtoken');
  if (token) {
    // User is logged in, start tracking inactivity
    addActivityListeners();
  }
});

// Clean up listeners on unmount
onUnmounted(() => {
  removeActivityListeners();
});
</script>

<template>
  <div>
    <template v-if="!isDashboardRoute">
      <HeaderComponent />
      <div class="absolute top-2 right-2 z-10">
        <div id="google_translate_element" style="max-height: 20px"></div>
      </div>
      <NavBarComponent />
    </template>

    <RouterView />

    <template v-if="!isDashboardRoute">
      <FooterComponent />
      <ScrollToTopButton />
    </template>

    <ChatComponent v-if="showChatIcon" />

    <!-- Inactivity Logout Message -->
    <div v-if="inactivityMessage" class="fixed bottom-5 right-5 bg-red-500 text-white p-4 rounded-lg shadow-lg">
      {{ inactivityMessage }}
    </div>
  </div>
</template>

<style>
@import '@/assets/main.css';
</style>
