<script setup lang="ts">
import { ref, computed, onMounted, onUnmounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import api from '@/services/api.js'

interface MenuItem {
  name: string
  link: string
  icon: string
  isMegaMenu: boolean
  subMenu?: MenuItem[]
  isOpen?: boolean
}

const menuItems = ref<MenuItem[]>([
  { name: 'Home', link: '/', icon: '', isMegaMenu: false },
  { name: 'Why Bhutan', link: '/why-bhutan', icon: '', isMegaMenu: false },
  {
    name: 'Sector Profile',
    link: '#',
    icon: '',
    isMegaMenu: true,
    subMenu: [],
    isOpen: false
  },
  {
    name: 'Investment Opportunities',
    link: '/investment-opportunities',
    icon: '',
    isMegaMenu: false
  },
  { name: 'FDI Regulation', link: '/fdi-regulation', icon: '', isMegaMenu: false },
  { name: 'Resources', link: '/resources', icon: '', isMegaMenu: false },
  { name: 'Service Provider', link: '/service-provider', icon: '', isMegaMenu: false },
  { name: 'About Us', link: '/about', icon: '', isMegaMenu: false },
  { name: 'Contact Us', link: '/contact', icon: '', isMegaMenu: false }
])

const route = useRoute()
const router = useRouter()

const isActive = computed(() => (path: string) => route.path === path)

const isOpen = ref(false)
const toggleMenu = () => {
  isOpen.value = !isOpen.value
}

// Sticky navbar logic
const isSticky = ref(false)

const checkSticky = () => {
  isSticky.value = window.pageYOffset > 200
}

onMounted(async () => {
  window.addEventListener('scroll', checkSticky)

  try {
    const response = await api.get('/webcontents/api/sector-profiles/')
    const sectors = response.data

    const sectorSubMenu = sectors.map((sector: { id: number; sector_type: { name: string } }) => ({
      name: sector.sector_type.name,
      link: `/sector-profile/${sector.id}`,
      icon: '',
      isMegaMenu: false
    }))

    // Update the Sector Profile submenu
    const sectorProfileItem = menuItems.value.find((item) => item.name === 'Sector Profile')
    if (sectorProfileItem) {
      sectorProfileItem.subMenu = sectorSubMenu
    }
  } catch (error) {
    console.error('Error fetching sector profiles:', error)
  }
})

onUnmounted(() => {
  window.removeEventListener('scroll', checkSticky)
})

// Submenu logic
const showSubmenu = (itemName: string) => {
  const item = menuItems.value.find(i => i.name === itemName)
  if (item) {
    item.isOpen = true
  }
}

const hideSubmenu = () => {
  menuItems.value.forEach(item => {
    if (item.isMegaMenu) {
      item.isOpen = false
    }
  })
}

const toggleSubmenu = (item: MenuItem) => {
  item.isOpen = !item.isOpen
}

defineExpose({})
</script>


<template>
  <nav
    :class="[
      'bg-bhutan-green shadow-lg transition-all duration-300',
      { 'fixed top-0 left-0 right-0 z-50 animate-slideDown': isSticky }
    ]"
  >
    <div class="container mx-auto px-4">
      <div class="flex justify-between items-center py-4">
        <button
          @click="toggleMenu"
          class="lg:hidden text-white hover:text-bhutan-green-light transition-colors duration-300"
        >
          <i class="fas fa-bars text-xl"></i>
        </button>
        <ul class="hidden lg:flex space-x-1 xl:space-x-2 justify-center w-full">
          <li v-for="item in menuItems" :key="item.name" class="relative group">
            <router-link
              v-if="!item.isMegaMenu"
              :to="item.link"
              :class="[
                'text-white hover:text-bhutan-green-light transition-colors duration-300 py-2 px-3 rounded-md flex items-center',
                { 'bg-bhutan-green-dark': isActive(item.link) }
              ]"
            >
              {{ item.name }}
            </router-link>
            <div
              v-else
              @mouseenter="showSubmenu(item.name)"
              @mouseleave="hideSubmenu"
              class="relative"
            >
              <button
                @click="toggleSubmenu(item)"
                :class="[
                  'text-white hover:text-bhutan-green-light transition-colors duration-300 py-2 px-3 rounded-md flex items-center',
                  { 'bg-bhutan-green-dark': item.isOpen }
                ]"
              >
                {{ item.name }}
                <i class="fas fa-chevron-down ml-1 text-xs"></i>
              </button>
              <ul
                v-if="item.subMenu"
                :class="[
                  'absolute bg-white shadow-lg rounded-md py-2 mt-2 z-10 w-56 transition-all duration-300 transform origin-top',
                  {
                    'scale-y-100 opacity-100': item.isOpen,
                    'scale-y-0 opacity-0': !item.isOpen
                  }
                ]"
              >
                <li v-for="subItem in item.subMenu" :key="subItem.name">
                  <a
                    :href="subItem.link"
                    :class="[
                      'block px-4 py-2 text-bhutan-green hover:text-white hover:bg-bhutan-green-light transition-colors duration-300',
                      { 'bg-bhutan-green-light text-white': isActive(subItem.link) }
                    ]"
                  >
                    {{ subItem.name }}
                  </a>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <!-- Mobile menu -->
    <div v-if="isOpen" class="lg:hidden bg-bhutan-green-light">
      <ul class="px-2 pt-2 pb-4 space-y-1">
        <li v-for="item in menuItems" :key="item.name">
          <div class="flex items-center justify-between">
            <router-link
              v-if="!item.isMegaMenu"
              :to="item.link"
              :class="[
                'block px-3 py-2 rounded-md text-base font-medium text-white hover:bg-bhutan-green-dark transition-colors duration-300',
                { 'bg-bhutan-green-dark': isActive(item.link) }
              ]"
            >
              {{ item.name }}
            </router-link>
            <button
              v-else
              @click="toggleSubmenu(item)"
              :class="[
                'flex justify-between items-center w-full px-3 py-2 rounded-md text-base font-medium text-white hover:bg-bhutan-green-dark transition-colors duration-300',
                { 'bg-bhutan-green-dark': item.isOpen }
              ]"
            >
              {{ item.name }}
              <i :class="['fas', item.isOpen ? 'fa-chevron-up' : 'fa-chevron-down']"></i>
            </button>
          </div>
          <ul
            v-if="item.isMegaMenu && item.subMenu"
            :class="['pl-4 mt-2 space-y-1 transition-all duration-300', { 'max-h-0 overflow-hidden': !item.isOpen, 'max-h-96': item.isOpen }]"
          >
            <li v-for="subItem in item.subMenu" :key="subItem.name">
              <a
                :href="subItem.link"
                :class="[
                  'block px-3 py-2 rounded-md text-base font-medium text-white hover:bg-bhutan-green-dark transition-colors duration-300',
                  { 'bg-bhutan-green-dark': isActive(subItem.link) }
                ]"
              >
                {{ subItem.name }}
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </nav>
</template>

<style scoped>
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css');

.group:hover > a {
  background-color: rgba(255, 255, 255, 0.1);
}

.animate-slideDown {
  animation: slideDown 0.3s ease-out;
}

@keyframes slideDown {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}

.scale-y-0 {
  transform: scaleY(0);
}

.scale-y-100 {
  transform: scaleY(1);
}
</style>
